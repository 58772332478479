import { getAPIHeaders } from '@/services/api.service';

async function getTasks(projectId, planningId) {
    const result = await fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks`, {
        headers: getAPIHeaders(),
    });
    return result.json();
}

async function createTasks(projectId, planningId, task) {
    const result = await fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks`, {
        headers: getAPIHeaders({ 'content-type': `application/json` }),
        method: 'POST',
        body: JSON.stringify(task),
    });
    return result.json();
}

async function deleteTasks(projectId, planningId, taskId) {
    const result = await fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks/${taskId}`, {
        headers: getAPIHeaders(),
        method: 'DELETE',
    });
    return result.json();
}

async function updateTasks(projectId, planningId, task) {
    const result = await fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks/${task.id}`, {
        headers: getAPIHeaders({ 'content-type': `application/json` }),
        method: 'PUT',
        body: JSON.stringify(task),
    });
    return result.json();
}

async function getPlannings(projectId) {
    const result = await fetch(`/api/projects/${projectId}/plannings`, {
        headers: getAPIHeaders(),
    });
    return result.json();
}

async function createPlanning(projectId, planning) {
    const result = await fetch(`/api/projects/${projectId}/plannings`, {
        headers: getAPIHeaders({ 'content-type': `application/json` }),
        method: 'POST',
        body: JSON.stringify(planning),
    });
    return result.json();
}

export default {
    getTasks,
    createTasks,
    updateTasks,
    getPlannings,
    createPlanning,
    deleteTasks,
};
