import { getMapBy, getMapById } from '@/services/sanitize.service';

const defaultLocation = { id: 'default', fullName: 'Ensemble' };

export function toTaskLBSLocationNode(id, name) {
    return {
        id,
        name,
        type: 'location',
        level: 0,
        children: [],
    };
}

export function toTaskLBSBundleNode(locationId, bundle) {
    return {
        ...bundle,
        id: locationId + '-' + bundle.id,
        locationId,
        name:
            bundle.type === 'company'
                ? (bundle.reference ? bundle.reference.padStart(3, '0') + ' - ' : '') + bundle.name
                : (bundle.reference ? bundle.reference + ' - ' : '') + bundle.name,
        type: 'bundle',
        class: 'text-blue-600',
        level: 1,
        children: [],
    };
}
export function toTaskLBSNode(node, parentId) {
    return {
        ...node,
        id: parentId ? parentId + '-' + node.id : node.id,
        locationId: node.locationId,
        name: node.name,
        type: 'service',
        level: node.level >= 0 ? node.level : 2,
        children: node.children || [],
    };
}

export function resolveLocation(task, locationsById, locationsByFullName) {
    if (task.locationId && locationsById[task.locationId]) {
        return locationsById[task.locationId];
    } else if (locationsByFullName[task.name]) {
        return locationsByFullName[task.name];
    }
    return null;
}

export function forceLocationInfos(task, location) {
    if (location) {
        task.name = location.fullName;
        task.locationId = location.id;
    }
}

export function getLeavesWithNoLocationSubTask(tasks) {
    const parentIds = tasks.reduce((acc, task) => {
        acc[task.parentId] = true;
        return acc;
    }, {});
    return tasks.filter((task) => task.type !== 'location' && !parentIds[task.id]);
}

export function getLocationSubTasks(tasks) {
    //explicit location
    const taskLocations = tasks.filter((task) => task.type === 'location');

    //implicit location (leaf, not location)
    const defaultLocatedTasks = getLeavesWithNoLocationSubTask(tasks);

    //create task location to a default location "Ensemble"
    const defaultLocationSubTasks = defaultLocatedTasks.map((task) => ({
        id: defaultLocation.id + '_' + task.id,
        locationId: defaultLocation.id,
        parentId: task.id,
        bundleId: task.bundleId,
    }));
    return [...taskLocations, ...defaultLocationSubTasks];
}

export function toTaskLBS(tasks, locations, bundles) {
    const result = [];

    const tasksCopy = tasks.map((e) => ({ ...e }));
    const locationsById = getMapById(locations);
    locationsById[defaultLocation.id] = defaultLocation;

    const locationsByFullName = getMapBy(locations, 'fullName');
    locationsByFullName[defaultLocation.fullName] = defaultLocation;

    const bundleMapById = getMapById(bundles);
    const locationNodeById = {};
    const bundleNodeCacheById = {};

    const locationSubTasks = getLocationSubTasks(tasksCopy);

    for (const task of locationSubTasks) {
        const location = resolveLocation(task, locationsById, locationsByFullName);
        forceLocationInfos(task, location);

        let locationNode = locationNodeById[location.id];
        if (!locationNode) {
            locationNode = toTaskLBSLocationNode(task.locationId, task.name);
            locationNodeById[location.id] = locationNode;
            result.push(locationNode);
        }

        let existingNode = bundleNodeCacheById[location.id + '-' + task.bundleId];
        if (!existingNode) {
            existingNode = toTaskLBSBundleNode(location.id, bundleMapById[task.bundleId]);
            bundleNodeCacheById[existingNode.id] = existingNode;
            locationNode.children.push(existingNode);
        }
        fillChildren(existingNode, tasksCopy);
    }
    const output = [];
    taskTreeToLines(result, output, null);
    return output;
}
function fillChildren(node, tasks) {
    node.children = tasks
        .filter(
            (task) =>
                task.type !== 'location' &&
                task.type !== 'bundle' &&
                ((task.parentId === null && node.id.endsWith(task.bundleId)) || node.id.endsWith(task.parentId)),
        )
        .map((task) => ({ ...task, id: node.id + '_' + task.id, level: node.level + 1 }));
    for (const subNode of node.children) {
        fillChildren(subNode, tasks);
    }
}
export function taskTreeToLines(roots, output, parentId) {
    if (roots) {
        for (const root of roots) {
            output.push(toTaskLBSNode(root, parentId));
            taskTreeToLines(root.children, output, root.id);
        }
    }
}

export default {
    toTaskLBS,
};
