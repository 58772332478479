<template>
    <MainMenu :menuItems="menuItems">
        <template v-slot:beforeItems>
            <li class="m-2 flex text-xs flex-col-reverse sm:flex-col items-center justify-center max-w-lg">
                <img
                    :src="project.imageUrl || '/defaultImage.svg'"
                    :alt="project.code + ' ' + project.name"
                    class="mx-2"
                    style="max-height: 10rem"
                />
                <div
                    class="bg-red-600 text-white text-xs w-full p-1 text-center"
                    v-if="project.tryAllRemaining !== null"
                >
                    <router-link :to="{ name: 'project', params: { ...$route.params } }" class="hover:underline">
                        <span v-if="project.tryAllRemaining > 0">
                            {{ $t('projects.expireIn') }} {{ project.tryAllRemaining }} {{ $t('projects.days') }}
                        </span>
                        <span v-if="project.tryAllRemaining < 0">
                            {{ $t('projects.expiredSince') }} {{ -project.tryAllRemaining }}
                            {{ $t('projects.days') }}
                        </span>
                        <span v-if="project.tryAllRemaining === 0">
                            {{ $t('projects.expiredToday') }}
                        </span>
                    </router-link>
                </div>
                <p class="text-xs text-gray-700 italic flex w-full px-1 mx-2">
                    <span v-if="project.code">{{ project.code }}</span>
                    <span class="flex-grow"></span>
                    <span>{{ project.phase ? $t('commons.phases.' + project.phase) : '' }}</span>
                </p>
                <span class="font-bold text-center hidden md:flex">{{ project.name }}</span>
            </li>
        </template>
    </MainMenu>
</template>
<script>
import MainMenu from '@/components/MainMenu';
import { isMobile } from '@/state/state';
import { getProject } from '@/features/projects/projects.service';
import { isDeliveryContactOnly } from '@/features/deliveryContacts/deliveryContacts.service';
export const menuItems = [
    {
        title: 'dashboard.title',
        menu: 'project.dashboard',
        icon: 'icon-finance',
        items: [
            {
                title: 'dashboard.myDashboard.title',
                route: 'myDashboard',
                menu: 'project.myDashboard',
                require: (project) => {
                    return project.projectFeatures.length > 0;
                },
            },
            {
                title: 'dashboard.projectDashboard.title',
                route: 'projectDashboard',
                menu: 'project.projectDashboard',
                requiredFeature: 'project_dashboard',
            },
            {
                title: 'dashboard.byBundleDashboardTitle',
                route: 'byBundleDashboard',
                menu: 'project.byBundleDashboardTitle',
                require: (project) => {
                    return project.me.bundleIds.length > 1 || project.me.allowedFeatures.includes('project_dashboard');
                },
            },
        ],
    },
    {
        title: 'project.information.title',
        menu: 'project.information',
        icon: 'icon-cog',
        items: [
            {
                title: 'project.information.generals.title',
                route: 'project',
                menu: 'project.information.generals',
            },
            {
                title: 'bundles.bundles',
                route: 'bundles',
                menu: 'project.information.bundles',
            },
            {
                title: 'project.information.folders.title',
                route: 'folders',
                menu: 'project.information.folders',
                requiredFeature: 'project_locations',
            },
            {
                title: 'services.title',
                route: 'services',
                menu: 'project.information.services',
                requiredFeature: 'project_services',
            },
            {
                title: 'supports.title',
                route: 'supports',
                menu: 'project.information.supports',
                require: (project) => {
                    return project.projectFeatures.length > 0;
                },
            },
            {
                title: 'project.information.deliveryContacts.title',
                route: 'deliveryContacts',
                menu: 'project.information.deliveryContacts',
                requiredFeature: 'project_deliveryContacts',
            },
        ],
    },
    {
        title: 'conceptions.title',
        menu: 'project.conception',
        icon: 'icon-head-lightbulb-outline',
        requiredFeature: 'project_CON',
        items: [
            {
                title: 'conceptions.elementTitle',
                route: 'conceptions',
                menu: 'project.conception',
            },
        ],
    },
    {
        title: 'project.planning.title',
        menu: 'project.planning',
        icon: 'icon-gantt',
        items: [
            ...(!isMobile
                ? [
                      {
                          title: 'project.planning.planningTitle',
                          route: 'planning',
                          menu: 'project.planning.planning',
                      },
                      {
                          title: 'project.planning.planningTitle2',
                          route: 'planning2',
                          menu: 'project.planning.planning2',
                      },
                  ]
                : []),
            {
                title: 'project.follow.references.title',
                route: 'references',
                menu: 'project.planning.references',
                requiredFeature: 'project_planning',
            },
            {
                title: 'project.follow.weatherIssues.title',
                route: 'weatherIssues',
                menu: 'project.planning.weatherIssues',
                requiredFeature: 'project_EXE',
            },
            {
                title: 'project.follow.agenda.title',
                route: 'agenda',
                menu: 'project.planning.agenda',
                requiredFeature: 'project_planning',
            },
        ],
    },
    {
        title: 'project.follow.title',
        menu: 'project.follow',
        icon: 'icon-format-list-checks',
        requiredFeature: ['project_OPR', 'project_EXE'],
        items: [
            ...(isMobile
                ? [
                      {
                          title: 'tour.title',
                          route: 'foldersTour',
                          menu: 'project.follow.tour',
                      },
                      {
                          title: 'project.follow.byBundle.title',
                          route: 'followByBundle',
                          menu: 'project.follow.byBundle',
                      },
                  ]
                : [
                      {
                          title: 'project.follow.byBundle.title',
                          route: 'followByBundle',
                          menu: 'project.follow.byBundle',
                      },
                      {
                          title: 'tour.title',
                          route: 'foldersTour',
                          menu: 'project.follow.tour',
                      },
                  ]),

            {
                title: 'project.follow.preparationMenuTitle',
                route: 'followPreparations',
                menu: 'project.follow.preparations',
                requiredFeature: 'project_EXE',
            },
            {
                title: 'syntheses.menuLabel',
                route: 'followSyntheses',
                menu: 'project.follow.syntheses',
                requiredFeature: 'project_EXE',
            },
            {
                title: 'project.follow.receptionMenuTitle',
                route: 'followReceptions',
                menu: 'project.follow.receptions',
                requiredFeature: 'project_EXE',
            },
            {
                title: 'project.follow.taskMenuTitle',
                route: 'followTasks',
                menu: 'project.follow.tasks',
                requiredFeature: 'project_EXE',
            },
            {
                title: 'project.follow.observationMenuTitle',
                route: 'followObservations',
                menu: 'project.follow.observations',
                requiredFeature: ['project_OPR', 'project_EXE'],
            },
            {
                title: 'project.follow.autoControlMenuTitle',
                route: 'followAutoControls',
                menu: 'project.follow.autoControls',
                requiredFeature: 'project_EXE',
            },
            {
                title: 'project.follow.certificateMenuTitle',
                route: 'followCertificates',
                menu: 'project.follow.certificates',
                requiredFeature: 'project_EXE',
            },
            {
                title: 'project.follow.requests.title',
                route: 'requests',
                menu: 'project.follow.requests',
                requiredFeature: 'project_requests',
            },
            {
                title: 'project.follow.meetings.title',
                route: 'followMeetings',
                menu: 'project.follow.meetings',
                requiredFeature: 'project_meetings',
            },
        ],
    },
    {
        title: 'project.editions.title',
        menu: 'project.editions',
        icon: 'icon-certificate',
        items: [
            {
                title: 'project.editions.meetingReports.title',
                route: 'meetingReports',
                menu: 'project.editions.meetingReports',
                requiredFeature: 'project_meetingReports',
            },
            {
                title: 'project.editions.meetingReports.title',
                route: 'readOnlyMeetingReports',
                menu: 'project.follow.meetings',
                require: (project) => {
                    return (
                        !project.me.allowedFeatures.includes('project_meetingReports') &&
                        project.projectFeatures.includes('project_EXE')
                    );
                },
            },
            {
                title: 'project.editions.observationsReports.menuTitle',
                route: 'observationsReports',
                menu: 'project.editions.observationsReports',
                require: (project) => {
                    return project.projectFeatures.length > 0;
                },
            },
            {
                title: 'project.editions.preparationsReports.title',
                route: 'preparationsReports',
                menu: 'project.editions.preparationsReports',
                requiredFeature: 'project_EXE',
            },
            {
                title: 'project.editions.conceptionsReports.title',
                route: 'conceptionsReports',
                menu: 'project.editions.conceptionsReports',
                requiredFeature: 'project_CON',
            },
            {
                title: 'project.editions.planningExports.title',
                route: 'planningExports',
                menu: 'project.editions.planningExports',
                requiredFeature: 'project_EXE',
            },
        ],
    },
    {
        title: 'project.diffusion.title',
        menu: 'project.diffusion',
        icon: 'icon-email-outline',
        items: [
            {
                title: 'project.diffusion.mailTemplates.menuTitle',
                route: 'mailTemplates',
                menu: 'project.diffusion.mailTemplates',
                requiredFeature: 'project_diffusion',
            },
        ],
    },
];
export default {
    components: { MainMenu },
    data: () => ({
        project: {},
        allowedFeatures: [],
    }),
    computed: {
        menuItems() {
            if (!this.project.me) {
                return [];
            } else if (isDeliveryContactOnly(this.project.me)) {
                return [
                    {
                        title: 'project.follow.requests.myRequests',
                        route: 'requests',
                        menu: 'project.follow.requests',
                        items: [],
                    },
                ];
            } else {
                return menuItems
                    .filter(
                        (item) =>
                            !item.requiredFeature ||
                            (Array.isArray(item.requiredFeature)
                                ? item.requiredFeature.find((feature) => this.allowedFeatures.includes(feature))
                                : this.allowedFeatures.includes(item.requiredFeature)),
                    )
                    .map((item) => {
                        return {
                            ...item,
                            items: item.items.filter(
                                (item) =>
                                    (!item.requiredFeature && !item.require) ||
                                    (item.require ? item.require(this.project) : false) ||
                                    (Array.isArray(item.requiredFeature)
                                        ? item.requiredFeature.find((feature) => this.allowedFeatures.includes(feature))
                                        : this.allowedFeatures.includes(item.requiredFeature)),
                            ),
                        };
                    })
                    .filter((item) => item.items.length > 0);
            }
        },
    },
    created() {
        this.subscriptions = [
            getProject(this.$route.params.projectId).subscribe(async (project) => {
                this.allowedFeatures = [...project.projectFeatures, ...project.me.allowedFeatures];
                this.project =
                    {
                        ...project,
                        tryAllRemaining: project.expireAt
                            ? Math.round((project.expireAt.getTime() - new Date().getTime()) / 3600 / 1000 / 24)
                            : null,
                    } || {};
            }),
        ];
    },
};
</script>
