<template>
    <svg ref="svg" @click="$emit('click')" :height="height">
        <template v-if="offDays && offDays.length">
            <rect
                fill="#deeaf5"
                :height="height"
                :y="0"
                :width="dayWidth"
                :x="day.left * dayWidth"
                v-for="day in offDays"
                :key="task.id + day.time"
            />
        </template>
        <text y="1em" :x="nameX" text-anchor="middle" :fill="task.isCritical ? 'red' : 'black'">
            {{ task.name }}
        </text>
        <template v-if="task.children?.length > 0 && !(task.summarize && task.collapsed)">
            <g>
                <svg
                    :x="groupX1"
                    y="1.7em"
                    :fill="
                        showReference && !showPlanned && !showReal
                            ? '#f74c14'
                            : !showReal
                            ? '#509cec'
                            : task.progress > 0
                            ? '#2a4365'
                            : '#509cec'
                    "
                >
                    <polygon points="0 0, 0 5, 5 0" />
                </svg>
                <rect
                    height="0.333em"
                    y="1.4em"
                    :width="groupWidth"
                    :x="groupX1"
                    :fill="
                        showReference && !showPlanned && !showReal
                            ? '#f74c14'
                            : !showReal
                            ? '#509cec'
                            : task.progress === 100
                            ? '#2a4365'
                            : '#509cec'
                    "
                    @mouseover="$emit('showTooltip', { lineType: 'group', dateRangeType: 'planned', ...task })"
                ></rect>
                <svg
                    :x="groupX2"
                    y="1.7em"
                    :fill="
                        showReference && !showPlanned && !showReal
                            ? '#f74c14'
                            : !showReal
                            ? '#509cec'
                            : task.progress === 100
                            ? '#2a4365'
                            : '#509cec'
                    "
                >
                    <polygon points="0 0, 5 0, 5 5" />
                </svg>
            </g>
            <rect
                fill="#2a4365"
                height="0.333em"
                y="1.4em"
                :width="groupProgressWidth"
                v-if="showReal && task.progressWidth"
                :x="groupX1"
            ></rect>
        </template>
        <template v-else-if="task.children?.length > 0 && task.summarize && task.collapsed">
            <g>
                <svg
                    :x="groupX1"
                    y="1.3em"
                    :fill="
                        showReference && !showPlanned && !showReal
                            ? '#f74c14'
                            : task.progress > 0
                            ? '#2a4365'
                            : '#509cec'
                    "
                >
                    <polygon points="0 0, 0 5, 5 0" />
                </svg>
                <rect
                    height="1px"
                    y="1.3em"
                    :width="groupWidth"
                    :x="groupX1"
                    :fill="
                        showReference && !showPlanned && !showReal
                            ? '#f74c14'
                            : task.progress === 100
                            ? '#2a4365'
                            : '#509cec'
                    "
                ></rect>

                <rect
                    v-for="(subTask, index) in task.children"
                    :height="1.3 / task.children.length + 'em'"
                    :y="1.3 + index * (1.3 / task.children.length) + 'em'"
                    :width="getTaskWidth(subTask)"
                    :x="getTaskX(subTask)"
                    :fill="
                        showReference && !showPlanned && !showReal
                            ? '#f74c14'
                            : subTask.progress === 100
                            ? '#2a4365'
                            : '#509cec'
                    "
                ></rect>

                <svg
                    :x="groupX2"
                    y="1.3em"
                    :fill="
                        showReference && !showPlanned && !showReal
                            ? '#f74c14'
                            : task.progress === 100
                            ? '#2a4365'
                            : '#509cec'
                    "
                >
                    <polygon points="0 0, 5 0, 5 5" />
                </svg>
            </g>
        </template>
        <template v-else>
            <rect
                fill="#509cec"
                :height="plannedHeight"
                :y="plannedY"
                :width="task.plannedWidth * dayWidth"
                :x="task.plannedLeft * dayWidth"
                @mouseover="$emit('showTooltip', { lineType: 'task', dateRangeType: 'planned', ...task })"
                v-if="showPlanned"
            ></rect>
            <rect
                fill="#509cec"
                height="0.6em"
                :y="realY"
                v-if="showReal && task.progressWidth"
                :width="task.realWidth * dayWidth"
                :x="task.realLeft * dayWidth"
                @mouseover="$emit('showTooltip', { lineType: 'task', dateRangeType: 'real', ...task })"
            ></rect>
            <rect
                fill="#2a4365"
                height="0.6em"
                :y="realY"
                :width="task.progressWidth * dayWidth"
                v-if="showReal && task.progressWidth"
                :x="task.progressLeft * dayWidth"
                @mouseover="$emit('showTooltip', { lineType: 'task', dateRangeType: 'progress', ...task })"
            ></rect>
        </template>
        <rect
            fill="#f74c14"
            :height="referenceHeight"
            :y="referenceY"
            :width="task.referenceWidth * dayWidth"
            :x="task.referenceLeft * dayWidth"
            v-if="showReference && task.referenceWidth && (task.children?.length === 0 || !referenceOnly)"
            @mouseover="$emit('showTooltip', { lineType: 'task', dateRangeType: 'reference', ...task })"
            @mouseout="$emit('hideTooltip')"
        ></rect>
        <text :y="dateY" :x="dateX1" text-anchor="end">
            {{ firstDate | humanizeDate }}
        </text>
        <text :y="dateY" :x="dateX2" text-anchor="start">
            {{ lastDate | humanizeDate }}
        </text>
        <template v-if="showProgressLine">
            <template v-if="task.children?.length > 0">
                <line
                    x1="0"
                    y1="0em"
                    :x2="0"
                    :y2="height"
                    style="stroke-width: 1"
                    :style="{ stroke: progressReportedTo === 'planned' ? '#2a4365' : '#8a2a0b' }"
                />
            </template>
            <template v-else>
                <line
                    x1="0"
                    y1="0em"
                    :x2="progressRight"
                    :y2="progressY"
                    style="stroke-width: 1"
                    :style="{ stroke: progressReportedTo === 'planned' ? '#2a4365' : '#8a2a0b' }"
                />
                <line
                    :x1="progressRight"
                    :y1="progressY"
                    x2="0"
                    :y2="height"
                    style="stroke-width: 1"
                    :style="{ stroke: progressReportedTo === 'planned' ? '#2a4365' : '#8a2a0b' }"
                />
            </template>
        </template>
    </svg>
</template>

<script>
import addDays from 'date-fns/addDays';

export default {
    props: [
        'value',
        'task',
        'dayWidth',
        'height',
        'showProgressLine',
        'showReference',
        'showReal',
        'showPlanned',
        'progressReportedTo',
        'refDate',
        'offDays',
    ],
    computed: {
        progressRight() {
            let left;
            let duration;
            let relativeStartDate;
            let relativeEndDate;
            if (this.progressReportedTo === 'planned') {
                left = this.task.plannedLeft;
                duration = this.task.duration;
                relativeEndDate = this.task.endDate;
                relativeStartDate = this.task.startDate;
            } else {
                left = this.task.referenceLeft;
                duration = this.task.referenceDuration;
                relativeEndDate = this.task.referenceEndDate;
                relativeStartDate = this.task.referenceStartDate;
            }
            if (
                (this.task.progress === 100 && relativeEndDate < this.refDate) ||
                (this.task.progress === 0 && relativeStartDate > this.refDate)
            ) {
                return 0;
            } else {
                return (left + (this.task.progress / 100) * duration) * this.dayWidth;
            }
        },
        progressY() {
            if (this.progressReportedTo === 'planned') {
                if (this.showPlanned || this.showReal) {
                    return '1.9em';
                } else {
                    return '1.5em';
                }
            } else {
                return '1.3em';
            }
        },
        referenceOnly() {
            return this.showReference && !this.showPlanned && !this.showReal;
        },
        realY() {
            if (this.showReference || this.showPlanned) {
                return '2.0em';
            } else {
                return '1.65em';
            }
        },
        plannedY() {
            if (this.showReference && this.showReal) {
                return '1.45em';
            } else if (this.showReal) {
                return '1.3em';
            } else {
                return '1.65em';
            }
        },
        plannedHeight() {
            if (this.showReal) {
                return '0.33em';
            } else {
                return '0.6em';
            }
        },
        referenceY() {
            if (this.task.children?.length > 0 || this.showPlanned || this.showReal) {
                return '1.1em';
            } else {
                return '1.65em';
            }
        },
        referenceHeight() {
            if (this.showPlanned || this.showReal) {
                return '0.33em';
            } else {
                return '0.6em';
            }
        },
        groupX2() {
            if ((this.showPlanned && this.showReal) || this.showReal) {
                return (
                    ((this.task.realLeft || this.task.plannedLeft) + (this.task.realWidth || this.task.plannedWidth)) *
                        this.dayWidth -
                    5
                );
            } else if (this.showPlanned) {
                return (this.task.plannedLeft + this.task.plannedWidth) * this.dayWidth - 5;
            } else if (this.showReference) {
                return (this.task.referenceLeft + this.task.referenceWidth) * this.dayWidth - 5;
            } else {
                return 0;
            }
        },
        groupX1() {
            if ((this.showPlanned && this.showReal) || this.showReal) {
                return (this.task.realLeft || this.task.plannedLeft) * this.dayWidth;
            } else if (this.showPlanned) {
                return this.task.plannedLeft * this.dayWidth;
            } else if (this.showReference) {
                return this.task.referenceLeft * this.dayWidth;
            } else {
                return 0;
            }
        },
        groupWidth() {
            if ((this.showPlanned && this.showReal) || this.showReal) {
                return (this.task.realWidth || this.task.plannedWidth) * this.dayWidth;
            } else if (this.showPlanned) {
                return this.task.plannedWidth * this.dayWidth;
            } else if (this.showReference) {
                return this.task.referenceWidth * this.dayWidth;
            } else {
                return 0;
            }
        },
        groupProgressWidth() {
            return (this.groupWidth * this.task.progress) / 100;
        },
        nameX() {
            return this.dateX1 + (this.dateX2 - this.dateX1) / 2;
        },
        dateX1() {
            if ((this.showPlanned && this.showReal) || this.showReal) {
                return (this.task.realStartDate ? this.task.realLeft : this.task.plannedLeft) * this.dayWidth - 10;
            } else if (this.showPlanned) {
                return this.task.plannedLeft * this.dayWidth - 10;
            } else if (this.showReference) {
                return this.task.referenceLeft * this.dayWidth - 10;
            } else {
                return 0;
            }
        },
        dateX2() {
            if ((this.showPlanned && this.showReal) || this.showReal) {
                return (
                    (this.task.realStartDate
                        ? this.task.realLeft + this.task.realWidth
                        : this.task.plannedLeft + this.task.plannedWidth) *
                        this.dayWidth +
                    10
                );
            } else if (this.showPlanned) {
                return (this.task.plannedLeft + this.task.plannedWidth) * this.dayWidth + 10;
            } else if (this.showReference) {
                return (this.task.referenceLeft + this.task.referenceWidth) * this.dayWidth + 10;
            } else {
                return 0;
            }
        },
        dateY() {
            if (
                this.task.children?.length > 0 ||
                [this.showReference, this.showPlanned, this.showReal].filter((a) => !!a).length === 1
            ) {
                return '2.3em';
            } else {
                return '2.6em';
            }
        },
        firstDate() {
            if ((this.showPlanned && this.showReal) || this.showReal) {
                return this.task.realStartDate || this.task.startDate;
            } else if (this.showPlanned) {
                return this.task.startDate;
            } else if (this.showReference) {
                return this.task.referenceStartDate;
            } else {
                return null;
            }
        },
        lastDate() {
            if ((this.showPlanned && this.showReal) || this.showReal) {
                return this.task.children?.length > 0
                    ? this.task.realEndDate || this.task.endDate
                    : this.task.realStartDate
                    ? this.task.estimatedEndDate
                    : this.task.endDate;
            } else if (this.showPlanned) {
                return this.task.endDate;
            } else if (this.showReference) {
                return this.task.referenceEndDate;
            } else {
                return null;
            }
        },
    },
    beforeMount() {
        window.addEventListener('mousedown', this.mousedown);
        window.addEventListener('touchstart', this.mousedown);

        document.addEventListener('mousemove', this.mousemove);
        document.addEventListener('touchmove', this.mousemove);

        document.addEventListener('mouseup', this.mouseup);
        document.addEventListener('touchend', this.mouseup);
    },
    beforeDestroy() {
        window.removeEventListener('mousedown', this.mousedown);
        window.removeEventListener('touchstart', this.mousedown);

        document.removeEventListener('mousemove', this.mousemove);
        document.removeEventListener('touchmove', this.mousemove);

        document.removeEventListener('mouseup', this.mouseup);
        document.removeEventListener('touchend', this.mouseup);
    },
    data: function () {
        return {
            isDragging: false,
            initialX: null,
            initialY: null,
        };
    },
    methods: {
        getTaskWidth(task) {
            if (this.showReal) {
                return (task.realWidth || task.plannedWidth) * this.dayWidth;
            } else if (this.showPlanned) {
                return task.plannedWidth * this.dayWidth;
            } else if (this.showReference) {
                return task.referenceWidth * this.dayWidth;
            }
        },
        getTaskX(task) {
            if (this.showReal) {
                return (task.realLeft || task.plannedLeft) * this.dayWidth;
            } else if (this.showPlanned) {
                return task.plannedLeft * this.dayWidth;
            } else if (this.showReference) {
                return task.referenceLeft * this.dayWidth;
            }
        },
        mousedown(event) {
            if (this.$el.contains(event.target)) {
                this.isDragging = true;
                this.initialX = event.pageX || event.touches[0].pageX;
                event.preventDefault();
            }
        },
        mouseup() {
            this.isDragging = false;
        },
        mousemove: function (event) {
            if (this.isDragging) {
                const newX = event.pageX || event.touches[0].pageX;
                const diffX = Math.round((newX - this.initialX) / this.dayWidth);
                this.$emit('input', addDays(this.value, -diffX));
                this.initialX = newX;
            }
        },
    },
};
</script>
