<template>
    <div ref="topRoot" class="relative">
        <div style="page-break-before: always" class="" v-for="(tasksGroup, groupIndex) in tasksGroups">
            <table class="w-full border-gray-200 border-solid table-fixed">
                <thead>
                    <tr>
                        <td :style="{ width: lineNumberColumnWidth }"></td>
                        <td :style="{ width: 'calc(' + nameColumnWidth + ')' }"></td>
                        <td :style="{ width: prrefColumnWidth }" v-if="numberOfData > 0"></td>
                        <td :style="{ width: datesColumnWidth }" v-if="numberOfData > 0"></td>
                        <td :style="{ width: datesColumnWidth }" v-if="numberOfData > 0"></td>
                        <td
                            :style="{ width: progressColumnWidth }"
                            v-if="options.showProgressColumn && numberOfData > 0"
                        ></td>
                        <td
                            :style="{ width: quantityColumnWidth }"
                            v-if="options.showQuantityColumn && numberOfData > 0"
                        ></td>
                        <td
                            :style="{ width: durationColumnWidth }"
                            v-if="options.showDurationColumn && numberOfData > 0"
                        ></td>
                        <td :style="{ width: lateColumnWidth }" v-if="options.showLateColumn && numberOfData > 0"></td>
                        <td></td>
                    </tr>
                    <tr class="border font-bold">
                        <td v-if="options.showLineNumbers" rowspan="2">&nbsp;</td>
                        <td class="p-1 align-bottom" rowspan="2" :colspan="numberOfData > 0 ? 2 : 1">
                            <header-cell :title="$t('commons.name')" iconSize="9px" />
                        </td>
                        <td class="p-1 align-bottom" rowspan="2" v-if="numberOfData > 0">
                            <header-cell :title="$t('project.follow.planning.startDate')" />
                        </td>
                        <td class="p-1 align-bottom" rowspan="2" v-if="numberOfData > 0">
                            <header-cell :title="$t('project.follow.planning.endDate')" />
                        </td>
                        <td class="p-1 align-bottom" rowspan="2" v-if="options.showProgressColumn && numberOfData > 0">
                            <header-cell
                                icon="icon-percent"
                                :title="$t('project.follow.planning.progress')"
                                iconSize="9px"
                            />
                        </td>
                        <td class="p-1 align-bottom" rowspan="2" v-if="options.showDurationColumn && numberOfData > 0">
                            <header-cell
                                icon="icon-arrow-expand-horizontal"
                                :title="$t('project.follow.planning.duration')"
                                iconSize="9px"
                            />
                        </td>
                        <td class="p-1 align-bottom" rowspan="2" v-if="options.showQuantityColumn && numberOfData > 0">
                            <header-cell
                                icon="icon-weight"
                                :title="$t('project.follow.planning.quantity')"
                                iconSize="9px"
                            />
                        </td>
                        <td class="p-1 align-bottom" rowspan="2" v-if="options.showLateColumn && numberOfData > 0">
                            <header-cell
                                icon="icon-clock-alert-outline"
                                :title="$t('project.follow.planning.late')"
                                iconSize="9px"
                            />
                        </td>
                        <td class="p-0 border ganttColumn" ref="ganttColumn">
                            <div
                                class="grid"
                                :style="{
                                    'grid-template-columns': 'repeat(' + numberOfDays + ', ' + dayWidth + 'px)',
                                }"
                            >
                                <div
                                    v-for="(step, index) in superSteps"
                                    v-if="step"
                                    :key="'hparent' + step.id"
                                    class="p-2 text-gray-700 truncate"
                                    :class="{ ...step.classes, 'border-l': index > 0 }"
                                    :style="{
                                        'grid-column': 'span ' + step.colspan + ' / span ' + step.colspan,
                                    }"
                                >
                                    <span>{{ step.label }}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="p-0 border">
                            <div
                                class="grid"
                                :style="{
                                    'grid-template-columns': 'repeat(' + numberOfDays + ', ' + dayWidth + 'px)',
                                }"
                            >
                                <div
                                    v-for="(step, index) in steps"
                                    :key="'hstep' + step.id"
                                    :style="{
                                        'grid-column': 'span ' + step.colspan + ' / span ' + step.colspan,
                                    }"
                                    class="text-gray-600 bg-white truncate text-center"
                                    :class="{ ...step.classes, firstStep: index === 0 }"
                                >
                                    <span>{{ step.label }}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(task, index) in tasksGroup">
                        <tr
                            :key="task.id"
                            class="line border"
                            style="page-break-inside: avoid"
                            :style="{
                                'min-height': (numberOfData === 3 ? 3 : 2) + 'em',
                                'max-height': (numberOfData === 3 ? 3 : 2) + 'em',
                            }"
                        >
                            <td :rowspan="lineRowSpan" class="border text-center text-gray-600 truncate">
                                <!-- number cell -->
                                <span v-if="options.showLineNumbers">
                                    {{ groupIndex * tasksGroups[0].length + index + 1 }}
                                </span>
                            </td>
                            <td class="border" :rowspan="lineRowSpan">
                                <!-- value cell -->
                                <div
                                    class="flex px-1 items-center overflow-hidden"
                                    :style="{
                                        width: nameColumnWidth,
                                        'padding-left':
                                            'calc(1em + ' + (task.level ? 16 * task.level + 'px' : '0') + ')',
                                    }"
                                    :class="{
                                        [task.class]: true,
                                    }"
                                >
                                    <span class="line-clamp-2" :class="{ 'text-red-700': task.isError }">
                                        {{ task.name }}
                                    </span>
                                    <span v-if="task.isError">
                                        <icon-all-inclusive
                                            width="9"
                                            height="9"
                                            :class="{ 'text-red-700': task.isError }"
                                            v-if="task.errorMessage === 'predecessor-loop'"
                                        />
                                        <icon-link-variant-off
                                            width="9"
                                            height="9"
                                            :class="{ 'text-red-700': task.isError }"
                                            v-if="task.errorMessage === 'missing-predecessor-error'"
                                        />
                                        <icon-help-rhombus
                                            width="9"
                                            height="9"
                                            :class="{ 'text-red-700': task.isError }"
                                            v-else
                                        />
                                    </span>
                                </div>
                            </td>
                            <table-gantt-reference-data-row
                                :task="task"
                                :options="options"
                                v-if="options.showReferenceData"
                            />
                            <table-gantt-planned-data-row
                                :task="task"
                                :options="options"
                                v-else-if="options.showPlannedData"
                            />
                            <table-gantt-real-data-row
                                :task="task"
                                :options="options"
                                v-else-if="options.showRealData"
                            />
                            <td :rowspan="lineRowSpan" class="p-0 border-l">
                                <div :style="{ height: lineHeight + 'px', width: '100%' }">
                                    <!-- gantt chart -->
                                    <gantt-line
                                        class="h-full"
                                        :task="task"
                                        :dayWidth="dayWidth"
                                        :width="numberOfDays * dayWidth"
                                        :show-progress-line="options.showProgressLine"
                                        :showReference="options.showReference"
                                        :showReal="options.showReal"
                                        :progressReportedTo="options.progressReportedTo"
                                        :showPlanned="options.showPlanned"
                                        :height="lineHeight"
                                        :viewBox.camel="svgOffset + ' 0 ' + numberOfDays * dayWidth + ' ' + lineHeight"
                                        :refDate="options.refDate"
                                        :offDays="agenda"
                                    ></gantt-line>
                                </div>
                            </td>
                        </tr>
                        <tr
                            :key="task.id + '_2'"
                            class="line border _2"
                            style="page-break-inside: avoid"
                            v-if="numberOfData > 1"
                        >
                            <table-gantt-planned-data-row
                                :task="task"
                                :options="options"
                                v-if="options.showReferenceData && options.showPlannedData"
                            />
                            <table-gantt-real-data-row
                                :task="task"
                                :options="options"
                                v-else-if="options.showRealData"
                            />
                        </tr>
                        <tr
                            :key="task.id + '_3'"
                            class="line border _3"
                            style="page-break-inside: avoid"
                            v-if="numberOfData === 3"
                        >
                            <table-gantt-real-data-row :task="task" :options="options" />
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import HeaderCell from './HeaderCell';
import GanttLine from './GanttLine';
import { getNumberOfDays, getSteps, getSuperSteps } from './stepService';
import { getTaskLeft, convertEmToPixels } from '@/components/gantt/ganttService';
import startOfDay from 'date-fns/startOfDay';
import { chunk } from '@/services/sanitize.service';
import TableGanttPlannedDataRow from '@/components/gantt/TableGanttPlannedDataRow';
import TableGanttRealDataRow from '@/components/gantt/TableGanttRealDataRow';
import TableGanttReferenceDataRow from '@/components/gantt/TableGanttReferenceDataRow';

export default {
    components: { TableGanttReferenceDataRow, TableGanttRealDataRow, TableGanttPlannedDataRow, GanttLine, HeaderCell },
    props: ['tasks', 'options', 'selectedItem', 'agenda', 'minDate', 'maxDate'],
    data: function () {
        return {
            dayWidth: 1,
            lineHeight: 0,
            clientHeight: 0,
            thead: 0,
            topRoot: 0,
            window: window,
            document: document,
            documentHeight: 0,
            windowSize: 0,
            lineNumberColumnWidth: '6ch',
            nameColumnWidth: '28ch + 46px',
            prrefColumnWidth: '1.5rem',
            datesColumnWidth: '4.5rem',
            durationColumnWidth: '2rem',
            quantityColumnWidth: '3rem',
            progressColumnWidth: '2.5rem',
            lateColumnWidth: '1.5rem',
            tasksByPageByRowSpanAndDocumentHeight: {
                2: {
                    768: 23, // A4 landscape
                    1010: 35, // A4 portrait
                    1024: 35, //  A3 landscape
                    1474: 53, //  A3 portrait
                    1536: 53, //  A2 landscape
                    2132: 77, //  A2 portrait
                    2048: 77, //  A1 landscape
                    3065: 111, //  A1 portrait
                    3072: 111, //  A0 landscape
                    4380: 160, //  A0 portrait
                },
                3: {
                    768: 17, // A4 landscape
                    1010: 26, // A4 portrait
                    1024: 26, //  A3 landscape
                    1474: 38, //  A3 portrait
                    1536: 38, //  A2 landscape
                    2132: 56, //  A2 portrait
                    2048: 56, //  A1 landscape
                    3065: 81, //  A1 portrait
                    3072: 81, //  A0 landscape
                    4380: 120, //  A0 portrait
                },
            },
        };
    },
    beforeMount() {
        window.addEventListener('resize', this.onResize);
        window.matchMedia('(orientation: landscape)').addEventListener('change', () => {
            this.onResize();
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.measureDayWidth();
            this.measureLineHeight();
            this.measureDocument();
        },
        measureDayWidth() {
            if (this.steps.length > 0 && this.$refs.ganttColumn && this.$refs.ganttColumn[0]) {
                this.dayWidth = this.$refs.ganttColumn[0].clientWidth / this.numberOfDays;
            }
        },
        measureLineHeight() {
            if (this.numberOfData === 3) {
                this.lineHeight = convertEmToPixels(3, this.$el) + 9 /*borders*/;
            } else {
                this.lineHeight = convertEmToPixels(2.5, this.$el) + 3 /*borders*/;
            }
        },
        measureDocument() {
            this.documentHeight = this.options.height || document.documentElement.clientHeight;
        },
    },
    watch: {
        tasks: function () {
            this.$nextTick(() => {
                this.onResize();
            });
        },
    },
    computed: {
        numberOfData() {
            return (
                (this.options.showRealData ? 1 : 0) +
                (this.options.showPlannedData ? 1 : 0) +
                (this.options.showReferenceData ? 1 : 0)
            );
        },
        lineRowSpan() {
            return (
                (this.options.showRealData ? 1 : 0) +
                    (this.options.showPlannedData ? 1 : 0) +
                    (this.options.showReferenceData ? 1 : 0) || 1
            );
        },
        tasksGroups() {
            const tasksNumber =
                this.tasksByPageByRowSpanAndDocumentHeight[this.numberOfData === 3 ? 3 : 2][this.documentHeight] ||
                this.tasks.length;
            return chunk(this.tasks, tasksNumber);
        },
        svgOffset() {
            return getTaskLeft(startOfDay(this.options.refDate), this.minDate) * this.dayWidth;
        },
        numberOfDays: function () {
            return getNumberOfDays(this.options.scale, this.minDate, this.maxDate);
        },
        steps: function () {
            return getSteps(this.options.scale, this.minDate, this.maxDate, this.agenda);
        },
        superSteps: function () {
            return getSuperSteps(this.options.scale, this.minDate, this.maxDate);
        },
    },
};
</script>

<style scoped>
.is-weekend {
    background-color: #f0f0f0;
}
.is-current-step {
    border-left-style: solid;
    border-left-width: thin;
}
thead {
    display: table-header-group;
    break-inside: avoid;
}
</style>
