<template>
    <main ref="main" class="h-full w-main relative min-h-main max-h-main flex flex-col p-2" @click="onClick">
        <div v-if="loading" class="flex justify-center">
            <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
        </div>
        <template v-else>
            <div ref="planningToolbar" class="flex text-xs gap-2">
                <app-select
                    v-if="plannings.length"
                    :label="$t('planning.selectedPlanning')"
                    v-model="selectedPlanningId"
                    @input="onPlanningSelect"
                >
                    <option :value="null"></option>
                    <template v-for="planning in plannings">
                        <option :value="planning.id">
                            {{ planning.name }}
                        </option>
                    </template>
                    <option value="new">- {{ $t('planning.newPlanning') }} -</option>
                </app-select>
                <app-select v-if="plannings.length" :label="$t('planning.structure')" v-model="structure">
                    <option value="BSL">{{ $t('planning.BSL') }}</option>
                    <option value="LBS">{{ $t('planning.LBS') }}</option>
                </app-select>
            </div>
            <div v-if="plannings.length === 0" class="flex justify-center w-100 p-5">
                <app-button
                    :label="$t('planning.newPlanning')"
                    @click="$refs.newPlanning.open()"
                    variant="primary"
                ></app-button>
            </div>
            <gantt
                v-if="hasTasks && !taskLoading"
                ref="gantt"
                :tasks="lines"
                :options="options"
                @select="onSelect"
                @contextMenu="onContextMenu"
                :selectedItem="selectedItem"
                :style="{ 'max-height': ganttMaxHeight, height: ganttMaxHeight }"
                class="overflow-hidden w-main px-1 text-xs"
                :agenda="agendaWithWeekEnds"
                :minDate="options.minDate"
                :maxDate="options.maxDate"
                @navigateToDate="navigateToDate($event)"
                @expand="expand"
                @expandDeep="expandDeep"
                @collapseDeep="collapseDeep"
                @collapse="collapse"
                @updateDuration="updateTaskDuration"
                @updateStartDate="updateTaskStartDate"
                @updateEndDate="updateTaskEndDate"
                @updateProgress="updateTaskProgress"
                @updateRealStartDate="updateTaskRealStartDate"
                @updateRealEndDate="updateTaskRealEndDate"
                @itemNameFocus="onItemNameFocus"
                @updatedName="renameTask"
            ></gantt>
            <hsc-menu-style-white>
                <hsc-menu-context-menu ref="contextMenu">
                    <div></div>
                    <template slot="contextmenu">
                        <hsc-menu-item
                            :label="$t('planning.newService')"
                            v-if="selectedItem && selectedItem.type === 'bundle'"
                            @click="addService"
                        />
                        <hsc-menu-item
                            :label="$t('planning.splitInSubTasks')"
                            v-if="selectedItem && selectedItem.children.length === 0 && selectedItem.type === 'service'"
                            @click="addService"
                        />
                        <hsc-menu-item
                            :label="$t('planning.addSubTask')"
                            v-if="selectedItem && selectedItem.type === 'directory'"
                            @click="addService"
                        />
                        <hsc-menu-item
                            :label="$t('planning.splitInLocations')"
                            v-if="
                                selectedItem &&
                                selectedItem.children.length === 0 &&
                                (selectedItem.type === 'service' || selectedItem.type === 'directory')
                            "
                            @click="addLocations"
                        />
                        <hsc-menu-item
                            :label="$t('planning.editLocations')"
                            v-if="
                                selectedItem &&
                                ((selectedItem.type === 'service' && selectedItem.children.length > 0) ||
                                    selectedItem.type === 'location' ||
                                    (selectedItem.type === 'directory' && selectedItem.children.length === 0))
                            "
                            @click="addLocations"
                        />
                        <hsc-menu-separator />
                        <hsc-menu-item :label="$t('commons.copy')" v-if="selectedItem" @click="copyNode" />
                        <hsc-menu-item :label="$t('commons.paste')" v-if="selectedItem" @click="pasteNode" />
                        <hsc-menu-item
                            :label="$t('commons.duplicate')"
                            v-if="selectedItem && selectedItem.type !== 'location' && selectedItem.type !== 'bundle'"
                            @click="duplicateNode"
                        />
                        <hsc-menu-item
                            :label="$t('commons.removeShort')"
                            :disabled="selectedItem && selectedItem.type === 'bundle'"
                            @click="removeNode"
                        />
                    </template>
                </hsc-menu-context-menu>
            </hsc-menu-style-white>
            <app-autocomplete
                ref="locationMenu"
                :options="locationOptions"
                @input="onLocation"
                :allowStringCriteria="true"
                string-criteria-prefix=""
                :filter-string-function="(item) => item.fullName || item.name"
            >
                <template v-slot:item="{ item }">
                    <span class="py-1">{{ item.fullName || item.name }}</span>
                </template>
            </app-autocomplete>
            <app-autocomplete
                ref="locationsMenu"
                :multiple="true"
                :options="locationOptions"
                @close="onLocations"
                :allowStringCriteria="true"
                string-criteria-prefix=""
                :filter-string-function="(item) => item.fullName"
                v-model="selectedItemLocations"
            >
                <template v-slot:item="{ item }">
                    <span class="py-1">{{ item.fullName || item.name }}</span>
                </template>
                <template v-slot:footer>
                    <app-footer @click="$refs.locationsMenu.close()" class="m-2"></app-footer>
                </template>
            </app-autocomplete>
        </template>
        <app-popup ref="newPlanning" :title="$t('planning.newPlanning')" :show-header="true">
            <ValidationObserver v-slot="{ invalid }" ref="observer">
                <div class="p-2 mx-4 flex flex-col gap-3">
                    <app-input-text v-model="newPlanning.name" :label="$t('commons.name')"></app-input-text>
                    <app-footer
                        @click="
                            $refs.newPlanning.close();
                            createPlanning({ name: newPlanning.name });
                            newPlanning.name = '';
                        "
                        :label="$t('commons.validate')"
                        class="w-full"
                        :disabled="invalid"
                    >
                        <template v-slot:left>
                            <app-button
                                variant="default"
                                :label="$t('commons.cancel')"
                                size="mini"
                                @click="$refs.newPlanning.close()"
                            />
                        </template>
                    </app-footer>
                </div>
            </ValidationObserver>
        </app-popup>
    </main>
</template>

<script>
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import Gantt from '../../../components/gantt/Gantt';
import AppSelect from '../../../components/appSelect/AppSelect';
import { isInViewport, sortBy } from '@/services/sanitize.service';
import { applyCollapse, cleanOrDefineMaxDate, getWeekEnds, mapAgenda } from '@/components/gantt/ganttService';
import hotkeys from 'hotkeys-js';
import startOfDay from 'date-fns/startOfDay';
import vueClickHelper from 'vue-click-helper';
import { queryProject } from '@/features/projects/projects.service';
import { getBundles } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { getReferences } from '@/features/planning/references/references.service';
import { updateTask } from '@/features/tasks/tasks.service';
import planning2Service from './planning2.service';
import planning2BSLService from './planning2BSL.service';
import planning2LBSService from './planning2LBS.service';
import AppTips from '@/components/app-tips/AppTips.vue';
import AppFooter from '@/components/appFooter/AppFooter.vue';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox.vue';
import AppButton from '@/components/appButton/AppButton.vue';
import AppInputText from '@/components/appInputText/AppInputText.vue';
import AppDateInput from '@/components/appDateInput/AppDateInput.vue';
import AppPopup from '@/components/app-popup/AppPopup.vue';
import AppAutocomplete from '@/components/app-autocomplete/AppAutocomplete.vue';
import locationService from '@/services/location.service';
import { queryPlanningState, updatePlanningState } from '@/state/state';
import format from 'date-fns/format';

export default {
    components: {
        AppAutocomplete,
        AppPopup,
        AppDateInput,
        AppInputText,
        AppButton,
        AppCheckbox,
        AppFooter,
        AppTips,
        AppSelect,
        Gantt,
    },
    directives: { 'click-helper': vueClickHelper },
    async created() {
        const projectId = this.$route.params.projectId;
        queryProject(projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_planning');
        });
        this.restorePlanningState();
        this.plannings = await planning2Service.getPlannings(projectId);
        if (this.selectedPlanningId === null && this.plannings.length > 0) {
            this.selectedPlanningId = this.plannings[0].id;
        }
        this.subscriptions = [
            combineLatest([
                getBundles(projectId),
                getCalendar(projectId),
                getLocationsTree(projectId),
                getReferences(projectId),
            ]).subscribe(async ([bundles, agenda, folders, references]) => {
                this.bundles = bundles;
                this.agenda = agenda;
                this.folders = folders;
                this.references = references;

                if (this.plannings[0]?.id) {
                    await this.loadTasks();
                    this.tasks = this.buildTaskTree();
                }
                this.loading = false;
            }),
        ];
    },
    watch: {
        structure() {
            this.tasks = this.buildTaskTree();
        },
    },
    updated: function () {
        this.updated();
    },
    data() {
        return {
            tasks: [],
            selectedItemLocations: [],
            contextMenuOpen: false,
            activeContextMenuOptions: [{ name: 'test1' }, { name: 'test3' }],
            bundleContextMenuOptions: [],
            serviceContextMenuOptions: [],
            locationContextMenuOptions: [],
            newPlanning: { name: this.$t('planning.newPlanningName') },
            selectedPlanningId: null,
            plannings: [],
            plannedTaskTrigger: new BehaviorSubject(new Date()),
            bundles: [],
            folders: [],
            agenda: [],
            loading: true,
            taskLoading: true,
            hasTasks: false,
            readOnly: true,
            structure: 'BSL',
            DBTasks: [],
            selectedItem: null,
            project: {},
            ganttMaxHeight: '100%',
            maxLevel: '99',
            options: {
                print: false,
                scale: 'months',
                name: '',
                fileName: null,
                maxDate: new Date(new Date().getTime() + 1000 * 24 * 30),
                minDate: new Date(),
                showCollapseButtons: true,
                showLineNumbers: false,
                showProgressLine: false,
                showReference: false,
                refDate: new Date(),
                showReal: false,
                showPlanned: false,
                progressReportedTo: 'reference',
                showReferenceData: false,
                showRealData: true,
                showLateColumn: true,
                showQuantityColumn: false,
                showDurationColumn: false,
                showPlannedData: true,
                showProgressColumn: true,
                showOutOfRangeLines: false,
                considerFilter: true,
                considerCollapsed: true,
                sortKey: 'date',
            },
            lastPlannedDate: null,
            references: [],
            collapsedLines: {},
            referenceId: null,
        };
    },
    mounted() {
        document.body.style.overflow = 'hidden';
        window.addEventListener('resize', this.onResize);
        hotkeys('down,ctrl+down', this.selectNextTask);
        hotkeys('up,ctrl+up', this.selectPreviousTask);
        hotkeys('right', this.next);
        hotkeys('left', this.previous);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        hotkeys.unbind('down,ctrl+down');
        hotkeys.unbind('up,ctrl+up');
        hotkeys.unbind('left');
        hotkeys.unbind('right');
        document.body.style.overflow = 'auto';
    },
    computed: {
        lines() {
            return applyCollapse(
                this.tasks.map((task) => ({ ...task, collapsed: this.collapsedLines[task.id] === true })),
            );
        },
        locationOptions() {
            return locationService.buildLocationOptions(this.folders);
        },
        agendaWithWeekEnds() {
            return mapAgenda(
                sortBy([...getWeekEnds(this.options.minDate, this.options.maxDate), ...this.agenda], 'time'),
                this.options.refDate,
            );
        },
    },
    methods: {
        async updateState() {
            this.options.maxDate = cleanOrDefineMaxDate(this.options.minDate, null, this.options.scale);
            updatePlanningState(this.$route.params.projectId, {
                selectedPlanningId: this.options.selectedPlanningId,
                scale: this.options.scale,
                structure: this.structure,
                minDate: format(this.options.minDate, 'yyyy-MM-dd'),
                refDate: this.options.refDate.toISOString(),
                selectedItemId: this.selectedItem?.id,
                showProgressLine: this.options.showProgressLine,
                showReference: this.options.showReference,
                showReal: this.options.showReal,
                progressReportedTo: this.options.progressReportedTo,
                showPlanned: this.options.showPlanned,
                showLateColumn: this.options.showLateColumn,
                showQuantityColumn: this.options.showQuantityColumn,
                showDurationColumn: this.options.showDurationColumn,
                showProgressColumn: this.options.showProgressColumn,
                showPlannedData: this.options.showPlannedData,
                showReferenceData: this.options.showReferenceData,
                showRealData: this.options.showRealData,
                sortKey: this.options.sortKey || 'date',
                showOutOfRangeLines: this.options.showOutOfRangeLines,
                filter: this.filterAsArray.map((item) =>
                    item._isStringCriteria ? item : { id: item.id, _isBundleCriteria: item._isBundleCriteria },
                ),
                considerFilter: this.options.considerFilter,
                considerCollapsed: this.options.considerCollapsed,
                collapsedLines: this.collapsedLines,
                referenceId: this.referenceId,
            });
        },
        restorePlanningState() {
            const planningState = queryPlanningState(this.$route.params.projectId);
            if (planningState.filter) {
                this.filterAsArray = [
                    ...this.filterOptions
                        .reduce((acc, item) => [...acc, ...item.children], [])
                        .filter(
                            (criterion) =>
                                !!planningState.filter.find((item) => {
                                    return item.id === criterion.id;
                                }),
                        ),
                    ...planningState.filter.filter((criterion) => criterion._isStringCriteria),
                ];
            }
            this.options.scale = planningState.scale || 'months';
            this.options.minDate = planningState.minDate ? new Date(planningState.minDate) : new Date();
            this.options.maxDate = cleanOrDefineMaxDate(this.options.minDate, null, this.options.scale);
            this.options.refDate = startOfDay(this.options.refDate || new Date());
            this.options.showProgressLine =
                typeof planningState.showProgressLine === 'boolean' ? planningState.showProgressLine : true;
            this.options.showReference = !!planningState.showReference;
            this.options.showReal = !!planningState.showReal;
            this.options.progressReportedTo = planningState.progressReportedTo || 'reference';
            this.options.showPlanned = !!planningState.showPlanned;
            this.options.showLateColumn = planningState.showLateColumn;
            this.options.showQuantityColumn = planningState.showQuantityColumn;
            this.options.showDurationColumn = planningState.showDurationColumn;
            this.options.showProgressColumn = planningState.showProgressColumn;
            this.options.showReferenceData = planningState.showReferenceData;
            this.options.sortKey = planningState.sortKey || 'date';
            this.options.showPlannedData =
                typeof planningState.showPlannedData === 'boolean' ? planningState.showPlannedData : true;
            this.options.showRealData =
                typeof planningState.showRealData === 'boolean' ? planningState.showRealData : true;
            this.options.showOutOfRangeLines =
                typeof planningState.showOutOfRangeLines === 'boolean' ? planningState.showOutOfRangeLines : false;
            this.options.considerCollapsed =
                typeof planningState.considerCollapsed === 'boolean' ? planningState.considerCollapsed : true;
            this.options.considerFilter =
                typeof planningState.considerFilter === 'boolean' ? planningState.considerFilter : true;

            this.structure = planningState.structure || 'BSL';
            this.referenceId = planningState.referenceId || null;
            this.collapsedLines = planningState.collapsedLines || {};
        },
        buildTaskTree() {
            if (this.structure === 'BSL') {
                return planning2BSLService.toTaskBSL(this.DBTasks, this.bundles);
            } else {
                return planning2LBSService.toTaskLBS(this.DBTasks, this.locationOptions, this.bundles);
            }
        },
        async renameTask() {
            await planning2Service.updateTasks(this.$route.params.projectId, this.selectedPlanningId, {
                id: this.selectedItem.id,
                name: this.selectedItem.name,
            });
        },
        async addService() {
            if (this.selectedItem.type === 'bundle') {
                const newTask = await planning2Service.createTasks(
                    this.$route.params.projectId,
                    this.selectedPlanningId,
                    {
                        bundleId: this.selectedItem.id,
                        parentId: null,
                        name: this.$t('planning.newServiceName'),
                        type: 'service',
                    },
                );
                const newNode = {
                    ...newTask,
                    level: this.selectedItem.level + 1,
                    editable: true,
                    children: [],
                };
                this.selectedItem.children.push(newNode);
                if (this.selectedItem.type === 'service') {
                    this.selectedItem.type = 'directory';
                    await planning2Service.updateTasks(this.$route.params.projectId, this.selectedPlanningId, {
                        id: this.selectedItem.id,
                        type: 'directory',
                    });
                }
                const index = this.tasks.indexOf(this.selectedItem);
                this.tasks.splice(index + 1, 0, newNode);
            } else {
                const newTask = await planning2Service.createTasks(
                    this.$route.params.projectId,
                    this.selectedPlanningId,
                    {
                        bundleId: this.selectedItem.bundleId,
                        parentId: this.selectedItem.id,
                        name: this.$t('planning.newServiceName'),
                        type: 'service',
                    },
                );
                const newNode = {
                    ...newTask,
                    level: this.selectedItem.level + 1,
                    editable: true,
                    children: [],
                };
                this.selectedItem.children.push(newNode);
                if (this.selectedItem.type === 'service') {
                    this.selectedItem.type = 'directory';
                    await planning2Service.updateTasks(this.$route.params.projectId, this.selectedPlanningId, {
                        id: this.selectedItem.id,
                        type: 'directory',
                    });
                }
                const index = this.tasks.indexOf(this.selectedItem);
                this.tasks.splice(index + 1, 0, newNode);
            }
        },
        onItemNameFocus() {
            if (this.selectedItem && this.selectedItem.type === 'location') {
                this.$refs.locationMenu.open();
            }
        },
        addLocations() {
            this.$refs.locationsMenu.open();
        },
        async removeNode() {
            const parentNode = this.tasks.find((task) => task.id === this.selectedItem.parentId);
            await planning2Service.deleteTasks(
                this.$route.params.projectId,
                this.selectedPlanningId,
                this.selectedItem.id,
            );
            const nodeIndex = this.tasks.findIndex((item) => item.id === this.selectedItem.id);
            this.tasks.splice(nodeIndex, 1);
            parentNode.children = parentNode.children.filter((node) => node.id !== this.selectedItem.id);
        },
        copyNode() {},
        pasteNode() {},
        async duplicateNode_Recursive(node, parentId) {
            const parentNode = this.tasks.find((task) => task.id === parentId);
            const parentIndex = this.tasks.indexOf(parentNode);
            const newTask = await planning2Service.createTasks(this.$route.params.projectId, this.selectedPlanningId, {
                bundleId: node.bundleId,
                parentId,
                name: node.name,
                type: node.type,
            });
            const newNode = {
                ...newTask,
                level: node.level,
                editable: node.editable,
                children: [],
            };
            this.tasks.splice(parentIndex + 1, 0, newNode);
            parentNode.children.push(newNode);
            await Promise.all(node.children.map((child) => this.duplicateNode_Recursive(child, newNode.id)));
        },
        duplicateNode() {
            this.duplicateNode_Recursive(this.selectedItem, this.selectedItem.parentId);
        },
        onLocation(selectedLocation) {
            this.selectedItem.name = selectedLocation.fullName || selectedLocation.name;
            this.selectedItem.locationId = selectedLocation._isStringCriteria ? null : selectedLocation.id;
            this.renameTask();
            const parentNode = this.tasks.find((task) => task.id === this.selectedItem.parentId);
            parentNode.children = sortBy(parentNode.children, 'name');
        },
        onLocations() {
            const parentNode =
                this.selectedItem.type === 'service'
                    ? this.selectedItem
                    : this.tasks.find((task) => task.id === this.selectedItem.parentId);

            const locationNodesToCreate = this.selectedItemLocations.filter(
                (location) =>
                    !parentNode.children.find((item) => item.name === location.fullName || item.name === location.name),
            );

            const locationNodesToRemove = parentNode.children.filter(
                (location) =>
                    !this.selectedItemLocations.find(
                        (item) => item.fullName === location.name || item.name === location.name,
                    ),
            );

            const parentIndex = this.tasks.findIndex((item) => item.id === parentNode.id);
            Promise.all([
                ...locationNodesToCreate.map(async (loc) => {
                    const newTask = await planning2Service.createTasks(
                        this.$route.params.projectId,
                        this.selectedPlanningId,
                        {
                            bundleId: parentNode.bundleId,
                            parentId: parentNode.id,
                            locationId: loc._isStringCriteria ? null : loc.id,
                            name: loc.fullName || loc.name,
                            type: 'location',
                        },
                    );
                    const newNode = {
                        ...newTask,
                        level: parentNode.level + 1,
                        editable: true,
                        children: [],
                        locationId: loc._isStringCriteria ? null : loc.id,
                    };
                    parentNode.children.push(newNode);
                    parentNode.children = sortBy(parentNode.children, 'name');
                    this.tasks.splice(
                        parentIndex + 1 + parentNode.children.findIndex((child) => child.name === newNode.name),
                        0,
                        newNode,
                    );
                }),
                ...locationNodesToRemove.map(async (loc) => {
                    await planning2Service.deleteTasks(this.$route.params.projectId, this.selectedPlanningId, loc.id);
                    const nodeIndex = this.tasks.indexOf(loc);
                    this.tasks.splice(nodeIndex, 1);
                    parentNode.children = parentNode.children.filter((node) => node.id !== loc.id);
                }),
            ]);
        },
        onClick() {
            if (this.contextMenuOpen) {
                this.$refs.contextMenu.close();
                this.contextMenuOpen = false;
            }
        },
        async createPlanning(planning) {
            const newPlanning = await planning2Service.createPlanning(this.$route.params.projectId, planning);
            this.plannings.unshift(newPlanning);
            this.selectedPlanningId = newPlanning.id;
            await this.loadTasks();
        },
        async loadTasks() {
            if (this.selectedPlanningId) {
                this.hasTasks = false;
                this.taskLoading = true;
                this.DBTasks = await planning2Service.getTasks(this.$route.params.projectId, this.selectedPlanningId);
                this.hasTasks = !!this.DBTasks && this.DBTasks.length > 0;
                this.taskLoading = false;
            }
        },
        onPlanningSelect(id) {
            if (id !== 'new') {
                this.loadTasks();
            } else {
                this.$refs.newPlanning.open();
                this.selectedPlanningId = null;
            }
        },
        updateTaskStartDate({ id, startDate }) {
            updateTask(this.$route.params.projectId, { id, startDate });
        },
        updateTaskRealEndDate({ id, realStartDate, realEndDate }) {
            updateTask(this.$route.params.projectId, {
                id,
                realEndDate,
                progress: 100,
                realStartDate: realStartDate || realEndDate,
            });
        },
        updateTaskEndDate({ id, endDate }) {
            updateTask(this.$route.params.projectId, { id, endDate });
        },
        updateTaskRealStartDate({ id, realStartDate }) {
            updateTask(this.$route.params.projectId, { id, realStartDate });
        },
        updateTaskDuration({ id, duration }) {
            updateTask(this.$route.params.projectId, { id, duration });
        },
        updateTaskProgress({ id, realStartDate, realEndDate, progress }) {
            if ((progress > 0 && progress < 100 && realStartDate) || progress === 0) {
                updateTask(this.$route.params.projectId, { id, progress });
            } else if (progress > 0 && progress < 100 && !realStartDate) {
                updateTask(this.$route.params.projectId, { id, progress, realStartDate: startOfDay(new Date()) });
            } else if (progress >= 100 && !realEndDate) {
                updateTask(this.$route.params.projectId, { id, progress: 100, realEndDate: startOfDay(new Date()) });
            } else if (progress >= 100) {
                updateTask(this.$route.params.projectId, { id, progress: 100 });
            }
        },
        selectNextTask(event) {
            if (event.target.nodeName === 'BODY') {
                const currentIndex = this.filteredLines.indexOf(
                    this.filteredLines.find((task) => task.id === this.selectedItem.id),
                );
                if (currentIndex >= 0) {
                    const nextTask = this.filteredLines.find((task, index) => {
                        return index > currentIndex && (!event.ctrlKey || task.children.length === 0);
                    });
                    if (nextTask) {
                        this.onSelect(nextTask);
                        event.preventDefault();
                        this.strollTo(nextTask);
                    }
                }
            }
        },
        selectPreviousTask(event) {
            if (event.target.nodeName === 'BODY') {
                const reversedTasks = [...this.filteredLines].reverse();
                const currentIndex = reversedTasks.indexOf(
                    reversedTasks.find((task) => task.id === this.selectedItem.id),
                );
                if (currentIndex >= 0) {
                    const previousTask = reversedTasks.find((task, index) => {
                        return index > currentIndex && (!event.ctrlKey || task.children.length === 0);
                    });
                    if (previousTask) {
                        this.onSelect(previousTask);
                        event.preventDefault();
                        this.strollTo(previousTask);
                    }
                }
            }
        },
        strollTo(task) {
            const element = document.querySelector('#uuid_' + task.id);
            if (element && !isInViewport(element)) {
                element.scrollIntoView();
            }
        },
        collapse: function (line) {
            this.collapsedLines = { ...this.collapsedLines, [line.id]: true };
            this.updateState();
        },
        collapseAll: function () {
            for (const line of this.lines) {
                this.collapsedLines[line.id] = true;
            }
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        expandToLevel: function (level) {
            for (const line of this.lines) {
                if (line.level < level) {
                    this.collapsedLines[line.id] = false;
                } else {
                    this.collapsedLines[line.id] = true;
                }
            }
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        expandDeep: function (task) {
            const taskIndex = this.lines.findIndex((aTask) => aTask.id === task.id);
            let indexOfNextTaskWithSameLevel = this.lines.findIndex(
                (aTask, index) => index > taskIndex && aTask.level === task.level,
            );
            if (indexOfNextTaskWithSameLevel === -1) {
                indexOfNextTaskWithSameLevel = this.lines.length - 1;
            }
            this.lines.map((aTask, index) => {
                if (index >= taskIndex && (!indexOfNextTaskWithSameLevel || index < indexOfNextTaskWithSameLevel)) {
                    this.collapsedLines[aTask.id] = false;
                }
            });
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        collapseDeep: function (task) {
            const taskIndex = this.lines.findIndex((aTask) => aTask.id === task.id);
            let indexOfNextTaskWithSameLevel = this.lines.findIndex(
                (aTask, index) => index > taskIndex && aTask.level === task.level,
            );
            if (indexOfNextTaskWithSameLevel === -1) {
                indexOfNextTaskWithSameLevel = this.lines.length - 1;
            }
            this.lines.map((aTask, index) => {
                if (index >= taskIndex && (!indexOfNextTaskWithSameLevel || index < indexOfNextTaskWithSameLevel)) {
                    this.collapsedLines[aTask.id] = true;
                }
            });
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        expandAll: function () {
            this.collapsedLines = {};
            this.updateState();
        },
        expand: function (line) {
            this.collapsedLines = { ...this.collapsedLines, [line.id]: false };
            this.updateState();
        },
        async navigateToDate(date) {
            this.options.minDate = date;
            await this.updateState();
        },
        updated: function () {
            if (this.$refs.main && this.$refs.planningToolbar) {
                this.ganttMaxHeight =
                    this.$refs.main.offsetHeight -
                    this.$refs.planningToolbar.offsetHeight -
                    (this.$refs.details ? this.$refs.details.offsetHeight : 0) +
                    'px';
                this.$refs.gantt?.onResize();
            }
        },
        onSelect(item) {
            this.selectedItem = item;
            if (item.type === 'location') {
                const parent = this.tasks.find((task) => task.id === item.parentId);
                this.selectedItemLocations = this.locationOptions.filter((location) =>
                    parent.children.find((item) => item.name === location.fullName),
                );
            } else {
                this.selectedItemLocations = this.locationOptions.filter((location) =>
                    item.children.find((item) => item.name === location.fullName),
                );
            }
            this.updateState();
        },
        onContextMenu({ event, item }) {
            event.preventDefault();
            event.stopPropagation();
            this.onSelect(item);
            const menu = this.$refs.contextMenu.$refs.menu;
            menu.open();
            menu.setPosition(event.pageX, event.pageY, 'right');
            this.contextMenuOpen = true;
            this.updateState();
        },
        today() {
            this.options.minDate = sub(startOfDay(new Date()), { [this.options.scale]: 8 });
            this.updateState();
        },
        next() {
            if (this.options.scale === 'days') {
                this.options.minDate = add(this.options.minDate, { days: 7 });
            } else if (this.options.scale === 'weeks') {
                this.options.minDate = add(this.options.minDate, { weeks: 3 });
            } else if (this.options.scale === 'months') {
                this.options.minDate = add(this.options.minDate, { months: 3 });
            }
            this.updateState();
        },
        previous() {
            if (this.options.scale === 'days') {
                this.options.minDate = sub(this.options.minDate, { days: 7 });
            } else if (this.options.scale === 'weeks') {
                this.options.minDate = sub(this.options.minDate, { weeks: 3 });
            } else if (this.options.scale === 'months') {
                this.options.minDate = sub(this.options.minDate, { months: 3 });
            }
            this.updateState();
        },
        expandTo(level) {
            if (level === 'all') {
                this.expandAll();
            } else {
                this.expandToLevel(parseInt(level));
            }
        },
    },
};
</script>
