import { sortBy } from '@/services/sanitize.service';

export function toTaskBSLBundleNode(bundle, children) {
    return {
        ...bundle,
        name:
            bundle.type === 'company'
                ? (bundle.reference ? bundle.reference.padStart(3, '0') + ' - ' : '') + bundle.name
                : (bundle.reference ? bundle.reference + ' - ' : '') + bundle.name,
        type: 'bundle',
        class: 'text-blue-600',
        level: 0,
        children,
    };
}

export function getChildrenFromParentId(node, tasks, result) {
    node.children = sortBy(
        tasks.filter((task) => task.parentId === node.id),
        'name',
    );
    for (const child of node.children) {
        child.level = node.level + 1;
        child.editable = true;
        result.push(child);
        getChildrenFromParentId(child, tasks, result);
    }
}

export function toTaskBSL(tasks, bundles) {
    const result = [];
    for (const bundle of bundles) {
        const bundleTasks = tasks.filter((task) => task.bundleId === bundle.id).map((e) => ({ ...e }));
        const bundleRootTasks = bundleTasks.filter((task) => task.parentId === null);
        result.push(toTaskBSLBundleNode(bundle, bundleRootTasks));
        for (const node of bundleRootTasks) {
            node.level = 1;
            node.editable = true;
            result.push(node);
            getChildrenFromParentId(node, bundleTasks, result);
        }
    }
    return result;
}

export default {
    toTaskBSL,
};
